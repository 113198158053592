<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto logo-icon-wrapper">
            <b-link
              class="navbar-brand float-left"
              to="/"
            >
              <span class="brand-logo">
                <img src="@/assets/images/logo/apollo-logo_28.png">
              </span>
            </b-link>

            <b-dropdown
              id="dropdown-1"
              right
              class="user-icon"
              no-caret
            >

              <template #button-content>
                <img
                  class="user-icon"
                  src="@/assets/images/icons/general-icons/user-icon.png"
                  size="40px"
                >
              </template>

              <b-dropdown-item @click="resetPasswordModal">
                <feather-icon
                  icon="KeyIcon"
                  size="20"
                  class="align-text-top"
                />
                Reset Password
              </b-dropdown-item>
            </b-dropdown>

          </li>

        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->

    <b-modal
      id="modal-center"
      ref="reset_password_modal"
      content-class="modal-wide"
    >

      <b-row class="">
        <b-col cols="12">
          <h1 class="title text-center">
            Change Password
          </h1>
          <div class="mt-1 mb-1 text-length-help-text">
            Minimum 8 characters long
          </div>
        </b-col>
      </b-row>

      <b-row>

        <b-col cols="6">
          <div class="form-group">
            <label
              class="label"
              for="current_password"
            >Current Password</label>
            <input
              id="current_password"
              v-model="current_password"
              placeholder="Current Password"
              :class="current_password_has_error ? 'is-invalid' : ''"
              :type="!view_current_password ? 'password' : 'text'"
              class="form-control"
            >
            <div
              class="field-icon"
              @click="view_current_password = !view_current_password"
            />
            <div
              v-if="empty_current_password"
              class="help-text danger-text"
            >
              Please provide current password
            </div>
            <div
              v-if="current_password_length_error"
              class="help-text danger-text"
            >
              Please provide a current password with min 8 characters length
            </div>
            <div
              v-if="current_password_wrong"
              class="help-text danger-text"
            >
              Current password is wrong
            </div>
          </div>
        </b-col>

        <b-col cols="6">
          <div class="form-group">
            <label
              class="label"
              for="new_password"
            >New Password</label>
            <input
              id="new_password"
              v-model="new_password"
              placeholder="New Password"
              :class="new_password_has_error ? 'is-invalid' : ''"
              :type="!view_new_password ? 'password' : 'text'"
              class="form-control"
            >
            <div
              class="field-icon"
              @click="view_new_password = !view_new_password"
            />
            <div
              v-if="empty_new_password"
              class="help-text danger-text"
            >
              Please provide new password
            </div>
            <div
              v-if="new_password_length_error"
              class="help-text danger-text"
            >
              Please provide a new password with min 8 characters length
            </div>
          </div>
        </b-col>

      </b-row>

      <template #modal-footer>
        <div class="modal-footer-buttons w-100">
          <button
            v-b-modal.modal-close_visit
            class="btn btn-primary m-1"
            @click="resetPassword"
          >
            Change password
          </button>
          <button
            v-b-modal.modal-close_visit
            class="btn btn-white m-1"
            @click="closeModal"
          >
            Close
          </button>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BLink, BImg, BAvatar, BModal,
} from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
    BAvatar,
    FeatherIcon,
    BModal,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      activePrompt: false,
      activePrompt2: false,
      valMultipe: {
        value1: '',
        value2: '',
      },
      view_current_password: false,
      view_new_password: false,
      current_password: null,
      new_password: null,
      current_password_has_error: false,
      new_password_has_error: false,
      empty_current_password: false,
      empty_new_password: false,
      current_password_length_error: false,
      new_password_length_error: false,
      current_password_wrong: false,

    }
  },

  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    const permissions = JSON.parse(localStorage.getItem('permissions') ?? '[]') || []

    const filteredMenu = navMenuItems.filter(item => (item.permission === '*') || permissions.includes(item.permission))

    return {
      navMenuItems: filteredMenu,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },
  computed: {
    validName() {
      return (this.valMultipe.value1.length > 0 && this.valMultipe.value2.length > 0)
    },
  },

  methods: {

    resetPasswordModal() {
      this.new_password = null
      this.current_password = null
      this.view_current_password = false
      this.view_new_password = false
      this.resetErrorsFields()
      this.$refs.reset_password_modal.show()
    },

    resetPassword() {
      this.resetErrorsFields()
      if (!this.new_password || this.new_password.trim() == '') {
        this.empty_new_password = true
        this.new_password_has_error = true
      } else if (this.new_password.length < 8) {
        this.new_password_length_error = true
        this.new_password_has_error = true
      } else {
        this.empty_new_password = false
        this.new_password_length_error = false
        this.new_password_has_error = false
      }

      if (!this.current_password || this.current_password.trim() == '') {
        this.empty_current_password = true
        this.current_password_has_error = true
      } else if (this.current_password.length < 8) {
        this.current_password_length_error = true
        this.current_password_has_error = true
      } else {
        this.empty_current_password = false
        this.current_password_length_error = false
        this.current_password_has_error = false
      }

      if (this.new_password_has_error || this.current_password_has_error) {
        return
      }

      this.$http.post(this.$resources.admins.changePassword, {
        old_password: this.current_password,
        new_password: this.new_password,
      }).then(response => {
        if (response.data.status == 4000) {
          this.current_password_wrong = true
        } else {
          this.$notify({ type: 'success', title: 'change password', text: 'password changed successfully' })
          this.$refs.reset_password_modal.hide()
        }
      })
    },

    resetErrorsFields() {
      this.empty_current_password = false
      this.empty_new_password = false
      this.current_password_length_error = false
      this.new_password_length_error = false
      this.current_password_has_error = false
      this.new_password_has_error = false
      this.current_password_wrong = false
    },

    closeModal() {
      this.$refs.reset_password_modal.hide()
    },
  },

}

</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";

  .user-icon{
    border-radius: 50% !important;
    margin-top:0px;
  }

.modal .modal-header .close {
  display: none;
}

.modal-footer-buttons {
  display: flex;
  justify-content: flex-start;
}

.logo-icon-wrapper ul.dropdown-menu {
    z-index: 999;
    position: relative;
}

.logo-icon-wrapper ul.dropdown-menu .dropdown-item {
    z-index: 999;
    position: relative;
}

.logo-icon-wrapper ul.dropdown-menu .dropdown-item:active,
.logo-icon-wrapper ul.dropdown-menu .dropdown-item:focus{
  background-color: white;
  color:#000000;
}

.dropdown-toggle:after {
 display: none;
}
.dropdown-icon {
  cursor: pointer;
}

.dropdown-toggle,.dropdown-toggle:active,.dropdown-toggle:focus {
  width: 40px !important;
  height: 40px !important;
  border: none !important;
  background: transparent !important;
  margin-top: 11px;
  box-shadow: none !important;
  padding: 0px;
}

.b-avatar .b-avatar-img {
  display: block  !important;
  width: 40px !important;
  height: 40px !important;
}

.modal-wide{
    width:665px !important;
    top: 170px !important;
    right: 100px !important;
}

.modal-wide .title {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: #1D1D1D;
  margin-top: 15px;
}

.modal-wide .text-length-help-text{
  color: #1d1d1d !important;
}

.modal-wide .danger-text{
  color:red;
}

.modal-wide .field-icon {
  width:17px;
  height: 17px;
  position: absolute;
  right: 17px;
  top: 40px;
  font-size: 14px;
  background-image: url('~@/assets/images/icons/general-icons/eye.png');
  background-repeat: no-repeat;
  cursor: pointer;
}

.modal-wide .btn-primary{
   background-color:#808080 !important;
   border:1px solid #808080 !important;

}

.modal-wide .modal-footer {
  border:none !important;
  padding: 0 0px 10px 15px !important;
  margin: 0 !important;
}

.modal-wide .modal-footer > * {
  padding: 0 !important;
  margin: 0 !important;
}

.modal-wide .modal-header {
  display: none !important;
}

.modal-wide  .modal-content {
  width:700px;
  max-width:700px;
  top:200px;
}

.modal-wide .label {
  margin-bottom:10px !important;
  color:#1D1D1D !important;
}

.modal-wide .help-text {
  margin-top:10px !important;
}

.modal-wide .modal-body {
  padding: 10px 30px 0px 30px !important;
}

.modal-wide .btn-white {
   border:1px solid #808080 !important
}

.modal-wide .modal  .modal-header {
  display: none !important;
}

.modal-wide .is-invalid {
  background-image: none !important;
}

.main-menu .btn-group{
  border:none !important
}

@media screen and (max-width: 1200px) {
  .user-icon{
    width: 25px !important;
    height: 25px !important;
  }
}
@media screen and (max-width: 667px) {
  .modal-wide{
      width:95% !important;
      top: 170px !important;
      right: 0px !important;
  }
}
</style>
